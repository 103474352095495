<template>
  <div class="share">
    <el-popover
      placement="top-start"
      popper-class="share-popover"
      title="分享"
      width="200"
      trigger="hover"
      v-if="top"
    >
      <div class="share-list">
        <div class="item wx" @click="shareWx()" title="微信分享">
          <el-tooltip
            class="item"
            effect="dark"
            content="微信分享"
            placement="top-start"
          >
            <i class="iconfont icon-weixin"></i>
          </el-tooltip>
        </div>
        <div class="item qq" @click="qqUser()" title="QQ好友分享">
          <el-tooltip
            class="item"
            effect="dark"
            content="QQ好友分享"
            placement="top-start"
          >
            <i class="iconfont icon-qq"></i>
          </el-tooltip>
        </div>
        <div class="item kj" @click="qqSpace()" title="分享至QQ空间">
          <el-tooltip
            class="item"
            effect="dark"
            content="分享至QQ空间"
            placement="top-start"
          >
            <i class="iconfont icon-QQzone-fill"></i>
          </el-tooltip>
        </div>
        <div class="item xl" @click="sina()" title="分享至新浪微博">
          <el-tooltip
            class="item"
            effect="dark"
            content="分享至新浪微博"
            placement="top-start"
          >
            <i class="iconfont icon-xinlang"></i>
          </el-tooltip>
        </div>
      </div>
      <span slot="reference">
        <slot />
      </span>
    </el-popover>
    <div class="share-list top" v-if="!top">
      <div class="item wx" @click="shareWx()" title="微信分享">
        <el-tooltip
          class="item"
          effect="dark"
          content="微信分享"
          placement="top-start"
        >
          <i class="iconfont icon-weixin"></i>
        </el-tooltip>
      </div>
      <div class="item qq" @click="qqUser()" title="QQ好友分享">
        <el-tooltip
          class="item"
          effect="dark"
          content="QQ好友分享"
          placement="top-start"
        >
          <i class="iconfont icon-qq"></i>
        </el-tooltip>
      </div>
      <div class="item kj" @click="qqSpace()" title="分享至QQ空间">
        <el-tooltip
          class="item"
          effect="dark"
          content="分享至QQ空间"
          placement="top-start"
        >
          <i class="iconfont icon-QQzone-fill"></i>
        </el-tooltip>
      </div>
      <div class="item xl" @click="sina()" title="分享至新浪微博">
        <el-tooltip
          class="item"
          effect="dark"
          content="分享至新浪微博"
          placement="top-start"
        >
          <i class="iconfont icon-xinlang"></i>
        </el-tooltip>
      </div>
    </div>
    <el-dialog
      class="wx-dialog"
      title="微信分享"
      :visible.sync="visible"
      :modal-append-to-body="true"
      :append-to-body="true"
      :lock-scroll="true"
      width="300px"
    >
      <el-image :src="codeUrl" lazy></el-image>
      <h3>请扫码分享至微信</h3>
    </el-dialog>
  </div>
</template>
<script>
import qrcode from "qrcode";
export default {
  components: {
    qrcode,
  },
  props: {
    id: {
      default: "",
      type: String | Number,
    },
    top: {
      default: true,
      type: Boolean,
    },
    url: {
      default: "",
      type: String,
    },
    image: {
      default: "",
      type: String,
    },
    title: {
      default: "",
      type: String,
    },
    desc: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      visible: false,
      codeUrl: "",
    };
  },
  methods: {
    upLoadNew() {
      this.$api
        .post("/api/v1/updateVoteShareCount-" + this.id)
        .then((res) => console.log(res));
    },
    qqUser() {
      this.upLoadNew();
      window.open(
        `http://connect.qq.com/widget/shareqq/index.html?url=${this.url}&sharesource=qzone&title=${this.title}&pics=${this.image}&summary=${this.desc}&desc=邀请您参与投票！`,
        "",
        `top=${($(window).height() - 500) / 2},left=${
          ($(window).width() - 500) / 2
        },width=500,height=500`
      );
    },
    qqSpace() {
      this.upLoadNew();
      window.open(
        `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${this.url}&sharesource=qzone&title=${this.title}&pics=${this.image}&summary=${this.desc}&desc=邀请您参与投票！`,
        "",
        `top=${($(window).height() - 500) / 2},left=${
          ($(window).width() - 500) / 2
        },width=500,height=500`
      );
    },
    sina() {
      this.upLoadNew();
      window.open(
        `http://service.weibo.com/share/share.php?url=${this.url}&sharesource=weibo&title=${this.title}-${this.desc}&pic=${this.image}&summary=${this.desc}&key=`,
        "",
        `top=${($(window).height() - 500) / 2},left=${
          ($(window).width() - 500) / 2
        },width=500,height=500`
      );
    },
    shareWx() {
      this.upLoadNew();
      this.visible = true;
      qrcode
        .toDataURL(this.url)
        .then((url) => {
          this.codeUrl = url;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.wx-dialog {
  text-align: center;
  .el-dialog__body {
    padding-top: 0;
  }
  .el-image {
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;
  }
  h3 {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    color: #999;
  }
}
.share-list {
  display: flex;
  &.top {
    .item {
      margin-right: 5%;
      i {
        font-size: 24px;
      }
    }
  }
  .item {
    padding: 0 5px;
    cursor: pointer;
    &.qq {
      i {
        color: #12b7f5;
      }
    }
    &.kj {
      i {
        color: #ffca06;
      }
    }
    &.wx {
      i {
        color: #02d869;
      }
    }
    &.xl {
      i {
        color: #e6162d;
      }
    }
    i {
      font-size: 32px;
    }
  }
}
.share-popover {
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8) !important;
  .el-popover__title {
    font-weight: 600;
  }
  .popper__arrow {
    border-top-color: rgba(255, 255, 255, 0.8) !important;
    &::after {
      border-top-color: rgba(255, 255, 255, 0.8) !important;
    }
  }
}
</style>